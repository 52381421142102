import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Login from './auth/login'
// import useGetMe from './hooks/auth/me'
import OrderReviewView from './pages/OrderReviewView/OrderReviewView'
import OrderView from './pages/OrderView/OrderView'
import TVView from './pages/TVView/TVView'

function AppRoutes() {
  // const { data } = useGetMe()
  return (
    <Routes>
      <Route element={<OrderView />} path="/orders_view" />
      <Route element={<TVView />} path="/TV_view" />
      <Route element={<OrderReviewView />} path="/orders_review" />
      <Route element={<Login />} path="/login" />
    </Routes>
  )
}
export default AppRoutes
