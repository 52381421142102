/* eslint-disable no-alert */
import React, { useMemo, useState } from 'react'
import { FaCloudDownloadAlt } from 'react-icons/fa'

import {
  Button,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'

import formatAddress from '../../../utils/formatAddress'
import SocketImage from '../../../assets/images/socket.svg'
import NegativeImage from '../../../assets/images/negative.svg'
import PositiveImage from '../../../assets/images/positive.svg'
import WithoutAlignImage from '../../../assets/images/without_align.png'

import { Order, OrderPrimaryProduct, OrderSteps, OrderType } from '../../../types'
import useMutateOrder from '../../../hooks/orders/useMutateOrder'

import DeliveryNoteMenu from './DeliveryNoteMenu'
import OrderItemReference from './OrderItemReference'
import OrderItemOrderStep from './OrderItemOrderStep'
import ZplFileReprintButton from './ZplFileReprintButton'
import OrderItemDeliveryService from './OrderItemDeliveryService'

function OrderItem({ order }: { order: Order }) {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [TrackingNum, setTrackingNum] = useState(order.trackingNum)
  const { mutate } = useMutateOrder(order.orderVytruveID)

  const designFileName = useMemo(() => {
    if (!order) return ''
    if (order.amputation === 'unknown' || Number.isNaN(parseInt(order.orderVytruveID.substring(0, 5), 10)))
      return order.orderVytruveID

    return order.orderVytruveID.substring(0, 5)
  }, [JSON.stringify(order)])

  const onTodoChangeTracking = (value: string) => {
    setTrackingNum(value)
  }

  const cancelOrder = (e) => {
    if (window.confirm(`Do you really want to cancel order ${e.target.id} ?`)) {
      mutate({
        orderStep: OrderSteps.CANCELED,
      })
    }
  }

  const downloadFile = (e) => {
    window.open(`${process.env.REACT_APP_API_BASE_URL}/zipFile/${e.target.id}`)
  }

  const downloadDeliveryFile = () => {
    window.open(`${process.env.REACT_APP_API_BASE_URL}/deliveryFile/${order.deliveryNoteFileID}`)
  }

  const handleKeyDownTracking = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      ;(e.target as HTMLInputElement).blur()
      mutate({
        trackingNum: (e.target as HTMLInputElement).value,
      })
    }
  }

  const downloadZPL = (e) => {
    // @ts-ignore
    const blob = new Blob([new Uint8Array(order.ZPLBuffer?.data).buffer], { type: 'text/plain' })
    const elem = window.document.createElement('a')
    // @ts-ignore
    elem.href = window.URL.createObjectURL(blob, { oneTimeOnly: true })
    elem.download = `${e.target.id}.txt`
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }

  const OrderVytruveID = order.orderVytruveID
  const OrderStep = order.orderStep
  const Address = order.address
  const OrderDate = order.orderDate
  const EstimatedDeliveryDate =
    order.orderType === OrderType.SLICING
      ? order.printerName || order.commissionNumber || ''
      : order.estimatedDeliveryDate
  const EstimatedSentDate = order.estimatedSentDate
  const DeliveryNoteFileID = order.deliveryNoteFileID
  const VytruveVersionNumber = order.vytruveVersionNumber
  let AddPlatine
  let AddDistal
  let AddValve
  let AddSupport
  let isDistal
  let distalScreenFront
  let distalScreenLateral
  if (order.distalData && order.distalData?.position?.length > 0) {
    isDistal = true
    distalScreenFront = `${process.env.REACT_APP_API_BASE_URL}/distalScreen/${order.distalData.frontalID}`
    distalScreenLateral = `${process.env.REACT_APP_API_BASE_URL}/distalScreen/${order.distalData.lateralID}`
  } else {
    isDistal = false
  }
  if (order.option) {
    if (order.option.addPlatineCA133STMX) {
      AddPlatine = 'CA133STMX'
    } else if (order.option.addPlatineCA134STMX) {
      AddPlatine = 'CA134STMX'
    } else {
      AddPlatine = 'Pas de platine'
    }
    if (order.option.addValve1S162) {
      AddValve = '1S162'
    } else if (order.option.addValveL551002) {
      AddValve = 'L-551002'
    } else {
      AddValve = 'Pas de valve'
    }
    if (order.option.addDistalLisseL651000) {
      AddDistal = 'Lisse L-651000'
    } else if (order.option.addDistalCrante6A2002) {
      AddDistal = 'Crante 6A200=2'
    } else if (order.option.addDistalBOAXPK3000) {
      AddDistal = 'BOA XPK3000'
    } else if (order.option.addDistalBOAXLR30107) {
      AddDistal = 'BOA XLR30107'
    } else if (order.option.addDistalBOAXLR3000_220) {
      AddDistal = 'BOA XRL3000-330'
    } else {
      AddDistal = 'Pas de Distal'
    }
    if (order.option.addLinerSupport) {
      AddSupport = 'Support manchon vytruve'
    } else {
      AddSupport = 'Pas de Support'
    }
  } else {
    AddPlatine = 'Platine pas définie'
    AddDistal = 'Distal pas définie'
  }

  const primaryProductInformation = useMemo(() => {
    switch (order.primaryProduct) {
      case OrderPrimaryProduct.NEGATIVE: {
        return { img: NegativeImage, label: 'negative' }
      }
      case OrderPrimaryProduct.POSITIVE: {
        return { img: PositiveImage, label: 'positive' }
      }
      case OrderPrimaryProduct.SOCKET_WITHOUT_ALIGN: {
        return { img: WithoutAlignImage, label: 'without alignment' }
      }
      default: {
        return { img: SocketImage, label: 'socket' }
      }
    }
  }, [JSON.stringify(order)])

  return (
    <>
      <Tr>
        <Td>
          <OrderItemReference order={order} />
        </Td>
        <Td>{VytruveVersionNumber}</Td>
        <Td>
          <OrderItemOrderStep order={order} />
        </Td>
        <Td textTransform="capitalize">{order.amputation}</Td>
        <Td>
          {isDistal ? (
            <Button colorScheme="gray" size="xs" onClick={onOpen}>
              Distal
            </Button>
          ) : (
            'valve'
          )}
        </Td>
        <Td>
          <Tooltip hasArrow label={primaryProductInformation.label}>
            <Image alt="primary product illustation" boxSize="20px" margin="auto" src={primaryProductInformation.img} />
          </Tooltip>
        </Td>
        <Td>{order.socketThickness || 'Unknown'}</Td>
        <Td>{order.clientName}</Td>
        <Td>{formatAddress(Address)}</Td>
        <Td>{OrderDate}</Td>
        <Td>{EstimatedSentDate}</Td>
        <Td>{EstimatedDeliveryDate}</Td>
        <Td>
          <OrderItemDeliveryService order={order} />
        </Td>
        <Td>{AddPlatine}</Td>
        <Td>{AddDistal}</Td>
        <Td>{AddValve}</Td>
        <Td>{AddSupport}</Td>
        <Td>
          <Input
            id={OrderVytruveID}
            name="fname"
            size="xs"
            type="text"
            value={TrackingNum}
            onChange={(e) => onTodoChangeTracking(e.target.value)}
            onKeyDown={handleKeyDownTracking}
          />
        </Td>
        <Td>
          <Button
            colorScheme="gray"
            id={`${order.fileS3Key}/${designFileName}.zip`}
            name="download"
            size="xs"
            type="button"
            onClick={downloadFile}
          >
            Download
          </Button>
        </Td>
        <Td>
          {DeliveryNoteFileID && (
            <HStack justifyContent="space-evenly" spacing="2">
              <IconButton
                aria-label="delivery note"
                colorScheme="blue"
                icon={<Icon as={FaCloudDownloadAlt} />}
                id={DeliveryNoteFileID}
                name="download"
                size="sm"
                type="button"
                onClick={downloadDeliveryFile}
              />
              <DeliveryNoteMenu orderVytruveID={OrderVytruveID} />
            </HStack>
          )}
        </Td>
        <Td>
          {order.ZPLBuffer && (
            <HStack justifyContent="space-evenly" spacing="2">
              <IconButton
                aria-label="zbl buffer"
                colorScheme="orange"
                icon={<Icon as={FaCloudDownloadAlt} />}
                id={TrackingNum}
                name="download"
                size="sm"
                type="button"
                onClick={downloadZPL}
              />
              <ZplFileReprintButton order={order} />
            </HStack>
          )}
        </Td>
        <Td>
          {OrderStep !== 'canceled' && OrderStep !== 'delivered' && OrderStep !== 'completed' && (
            <Button
              colorScheme="red"
              id={OrderVytruveID}
              name="cancel"
              size="xs"
              style={{ fontWeight: 'bold' }}
              type="button"
              onClick={cancelOrder}
            >
              Cancel
            </Button>
          )}
        </Td>
      </Tr>
      <Modal isCentered isOpen={isOpen} size="full" onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          containerProps={{
            padding: { base: '0', md: '10' },
          }}
          minH="100%"
        >
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <img alt="distal front" src={distalScreenFront} />
              </div>
              <div style={{ flex: 1 }}>
                <img alt="distal lateral" src={distalScreenLateral} />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default OrderItem
