import React from 'react'
import { FaEllipsisV } from 'react-icons/fa'

import { Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'

import { DeliveryNoteLanguage } from '../../../types'
import useGetDeliveryFileLang from '../../../hooks/delivery-file/useGetDeliveryFileLang'

function DeliveryNoteMenu({ orderVytruveID }: { orderVytruveID: string }) {
  const mutationDownloadDeliveryNoteLang = useGetDeliveryFileLang(orderVytruveID)
  const downloadDeliveryLocalizedFile = async (lang) => {
    await mutationDownloadDeliveryNoteLang.mutate({ lang })
  }
  return (
    <Menu>
      <MenuButton aria-label="more option download" data-testid="menu-delivery-note" name="download">
        <Icon as={FaEllipsisV} />
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => downloadDeliveryLocalizedFile(DeliveryNoteLanguage.FR)}>French</MenuItem>
        <MenuItem onClick={() => downloadDeliveryLocalizedFile(DeliveryNoteLanguage.EN)}>English</MenuItem>
        <MenuItem onClick={() => downloadDeliveryLocalizedFile(DeliveryNoteLanguage.DE)}>German</MenuItem>
        <MenuItem onClick={() => downloadDeliveryLocalizedFile(DeliveryNoteLanguage.IT)}>Italian</MenuItem>
      </MenuList>
    </Menu>
  )
}

export default DeliveryNoteMenu
