import { Order, PaginationQueryParams } from '../../types'
import findAllHooksFactory from '../../utils/factories/findAllHooksFactory'

export interface GetOrdersGoTodayParams extends PaginationQueryParams {
  q?: string
}

export const { useGetEntity: useGetOrdersGoToday, useInfiniteGetEntity: useInfiniteGetOrdersGoToday } =
  findAllHooksFactory<Order, GetOrdersGoTodayParams>({
    endpoint: 'orders/gotoday',
    queryKey: 'orders-gotoday',
  })
