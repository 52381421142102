import React, { useMemo } from 'react'
import { GrValidate } from 'react-icons/gr'
import { SiBlueprint } from 'react-icons/si'
import { BsTruck } from 'react-icons/bs'

import {
  Button,
  Center,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import useMutateOrder from '../../../hooks/orders/useMutateOrder'
import { orderSteps } from '../../../constants/order'
import { Order, OrderSteps } from '../../../types'

interface OrderItemOrderStepProps {
  order: Order
}

function OrderItemOrderStep({ order }: OrderItemOrderStepProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { mutate } = useMutateOrder(order.orderVytruveID)

  const nextStep = useMemo(() => {
    if (order.orderStep === OrderSteps.NEED_SLICING) {
      return OrderSteps.SLICING_DONE
    }

    if (order.orderStep === OrderSteps.WRIKE_TASK_CREATED) {
      return OrderSteps.PRINTING
    }

    if (order.orderStep === OrderSteps.PRINTING) {
      return OrderSteps.TO_SEND
    }

    if (order.orderStep === OrderSteps.TO_SEND) {
      return OrderSteps.DELIVERY
    }
    return undefined
  }, [JSON.stringify(order)])

  const handleOrderStepNext = () => {
    mutate({ orderStep: nextStep })
    onClose()
  }

  const modalBodyText = () => {
    if (order.orderStep === OrderSteps.NEED_SLICING) {
      return { text: `Are you sure you want to set the status "${nextStep}"?` }
    }

    if (order.orderStep === OrderSteps.WRIKE_TASK_CREATED) {
      return {
        icon: SiBlueprint,
        text: `Are you sure you want to set the status "${nextStep}"? It's like you validate the wrike case "printing"`,
      }
    }

    if (order.orderStep === OrderSteps.PRINTING) {
      return {
        icon: GrValidate,
        text: `Are you sure you want to set the status "${nextStep}"? It's like you validate the wrike case "quality check"`,
      }
    }

    if (order.orderStep === OrderSteps.TO_SEND) {
      return {
        icon: BsTruck,
        text: `Are you sure you want to set the status "${nextStep}"? It's like you validate the wrike case "in process of being delivered"`,
      }
    }
    return {}
  }

  const element = modalBodyText()

  const orderStep = orderSteps.find((step) => step.value === order.orderStep)?.label || order.orderStep

  return (
    <Center>
      {nextStep ? (
        <Button
          colorScheme={order.orderStep === OrderSteps.NEED_SLICING ? 'orange' : 'gray'}
          size="sm"
          onClick={onOpen}
        >
          {orderStep}
        </Button>
      ) : (
        <Text textAlign="center">{orderStep}</Text>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{order.orderVytruveID}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{element.text}</Text>
            {element.icon && (
              <Center padding="6">
                <Icon as={element.icon} boxSize="14" />
              </Center>
            )}
          </ModalBody>
          <ModalFooter>
            <Button marginRight="3" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="green" onClick={handleOrderStepNext}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Center>
  )
}

export default OrderItemOrderStep
