import { useToast, UseToastOptions } from '@chakra-ui/react'

interface ToastProps {
  description: string
  title: string
  status: UseToastOptions['status']
}

function Toast() {
  const toast = useToast()
  return ({ description, title, status }: ToastProps) =>
    toast({
      description,
      duration: 9000,
      isClosable: true,
      position: 'bottom-right',
      status,
      title,
    })
}

export default Toast
