import { useQuery } from '@tanstack/react-query'

import { Order } from 'src/types'
import Api from '../../utils/api'

function useGetReviewOrders() {
  return useQuery(['orders-review'], async () => {
    const { data } = await Api.get<Order[]>(`/orders/review`)
    return data
  })
}

export default useGetReviewOrders
