/* eslint-disable react/prop-types */
import React from 'react'

import { Td, Tr } from '@chakra-ui/react'
import { Order, OrderDeliveryService, OrderSteps, TVorderTable } from '../../types'

interface DisplayOrderItemProps {
  order: Order
  type: TVorderTable
}

function DisplayOrderItem({ order, type }: DisplayOrderItemProps) {
  const OrderVytruveID = order.orderVytruveID
  const OrderStep = order.orderStep
  const Address = order.address
  const OrderDate = order.orderDate
  const EstimatedDeliveryDate = order.estimatedDeliveryDate
  const EstimatedSentDate = order.estimatedSentDate
  const DeliveryService = order.deliveryService
  const TrackingNum = order.trackingNum

  let AddPlatine
  let AddDistal
  let AddValve
  let AddSupport
  let isDistal
  if (order.distalData?.position?.length) {
    isDistal = true
  } else {
    isDistal = false
  }
  if (order.option) {
    if (order.option.addPlatineCA133STMX) {
      AddPlatine = 'CA133STMX'
    } else if (order.option.addPlatineCA134STMX) {
      AddPlatine = 'CA134STMX'
    } else {
      AddPlatine = 'Pas de platine'
    }
    if (order.option.addValve1S162) {
      AddValve = '1S162'
    } else if (order.option.addValveL551002) {
      AddValve = 'L-551002'
    } else {
      AddValve = 'Pas de valve'
    }
    if (order.option.addDistalLisseL651000) {
      AddDistal = 'Lisse L-651000'
    } else if (order.option.addDistalCrante6A2002) {
      AddDistal = 'Crante 6A200=2'
    } else if (order.option.addDistalBOAXPK3000) {
      AddDistal = 'BOA XPK3000'
    } else if (order.option.addDistalBOAXLR30107) {
      AddDistal = 'BOA XLR30107'
    } else if (order.option.addDistalBOAXLR3000_220) {
      AddDistal = 'BOA XRL3000-330'
    } else {
      AddDistal = 'Pas de Distal'
    }
    if (order.option.addLinerSupport) {
      AddSupport = 'Support manchon vytruve'
    } else {
      AddSupport = 'Pas de Support'
    }
  } else {
    AddPlatine = 'Platine pas définie'
    AddDistal = 'Distal pas définie'
  }

  return (
    <Tr color={order.orderStep === OrderSteps.TO_SEND ? 'gray.400' : undefined}>
      <Td>
        {DeliveryService === OrderDeliveryService.EXPRESS ? (
          <h3>
            <b style={{ color: 'red' }}>{OrderVytruveID}</b>
          </h3>
        ) : (
          <h3>
            <b>{OrderVytruveID}</b>
          </h3>
        )}
      </Td>
      <Td>
        <h3>
          <b>{EstimatedSentDate}</b>
        </h3>
      </Td>
      {type === TVorderTable.TO_PRINT && <Td>{order.socketThickness || 'Unknown'}</Td>}
      <Td>
        <h3>
          <b>{Address.name}</b>
        </h3>
      </Td>
      <Td>{isDistal ? 'Distal' : 'Valve'}</Td>
      <Td>{OrderStep}</Td>
      <Td>{OrderDate}</Td>
      <Td>{EstimatedDeliveryDate}</Td>
      <Td>{DeliveryService === 'express' ? <b style={{ color: 'red' }}>{DeliveryService}</b> : DeliveryService}</Td>
      <Td>{AddPlatine}</Td>
      <Td>{AddDistal}</Td>
      <Td>{AddValve}</Td>
      <Td>{AddSupport}</Td>
      <Td>{TrackingNum}</Td>
    </Tr>
  )
}

export default DisplayOrderItem
