import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'

import { OrderSteps } from '../../types'
import { useGetOrders } from '../../hooks/orders/useGetOrders'

function OrderTableSlicing() {
  const { data } = useGetOrders({ limit: 1000, offset: 0, orderStep: OrderSteps.NEED_SLICING })
  return (
    <Box>
      {data && data.results.length > 0 && (
        <>
          <Text fontSize="4xl">Orders to Slice ({data.count})</Text>
          <Table colorScheme="orange" size="sm" variant="striped" width="full">
            <Thead>
              <Tr>
                <Th>OrderVytruveID</Th>
                <Th>Client</Th>
                <Th>OrderDate</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.results.map((order) => (
                <Tr>
                  <Td>{order.orderVytruveID}</Td>
                  <Td>{order.client}</Td>
                  <Td>{order.orderDate}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}
    </Box>
  )
}

export default OrderTableSlicing
