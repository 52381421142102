import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const EMPTY_VALUES = [undefined, '', null]

function useSearchParamsFilters<T extends Object>() {
  const [searchParams, setSearchParams] = useSearchParams()
  const parsedSearchParams = Object.fromEntries(searchParams)
  const [innerSearchParams, setInnerSearchParams] = useState(parsedSearchParams)

  const handleSetSearchParamsFromObject = (filters: T) => {
    const cleanedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
      const isArray = Array.isArray(value)
      const isEmptyArray = isArray && value.length === 0
      if (!EMPTY_VALUES.includes(value) && !isEmptyArray) {
        return { ...acc, [key]: isArray ? value.toString() : value }
      }
      return acc
    }, {})
    setInnerSearchParams(cleanedFilters)
  }

  useEffect(() => {
    if (JSON.stringify(parsedSearchParams) !== JSON.stringify(innerSearchParams)) {
      setSearchParams(innerSearchParams, { replace: true })
    }
  }, [JSON.stringify(innerSearchParams)])

  return {
    parsedSearchParams,
    searchParams,
    setSearchParams: handleSetSearchParamsFromObject,
  }
}

export default useSearchParamsFilters
