import { useMutation } from '@tanstack/react-query'

import { DeliveryNoteLanguage } from 'src/types'
import Api from '../../utils/api'

function useGetDeliveryFileLang(id: string) {
  return useMutation(
    ['download-delivery-file-lang'],
    async (body: { lang: DeliveryNoteLanguage }) => {
      const { data } = await Api.post<Blob>(`/deliveryFile/lang/${id}`, body, { responseType: 'blob' })
      return data
    },
    {
      onSuccess: (data, { lang }) => {
        const href = URL.createObjectURL(data)

        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', `delivery_file_${id}_${lang}.pdf`) // or any other extension
        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      },
    }
  )
}

export default useGetDeliveryFileLang
