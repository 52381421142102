export enum OrderSteps {
  TBC = 'to be continued',
  RECEIVED = 'received',
  VALIDATED = 'validated',
  WRIKE_TASK_CREATED = 'wrike task created',
  PRINTING = 'printing',
  TO_SEND = 'to send',
  DELIVERY = 'delivery',
  DELIVERED = 'delivered',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  NEED_SLICING = 'need_slicing',
  SLICING_DONE = 'slicing_done',
}

export interface OrderWrikeStep {
  'Impression en cours': boolean
  'Check Qualité': boolean
  "En cours d'acheminement": boolean
}

export enum OrderDeliveryService {
  STANDARD = 'standard',
  EXPRESS = 'express',
}

export interface OrderOption {
  addPlatineCA133STMX: boolean
  addPlatineCA134STMX: boolean
  addDistalLisseL651000: boolean
  addDistalCrante6A2002: boolean
  addDistalBOAXPK3000: boolean
  addDistalBOAXLR30107: boolean
  addDistalBOAXLR3000_220: boolean
  addValve1S162: boolean
  addValveL551002: boolean
  addLinerSupport: boolean
  assembleSocket: boolean
}

export interface OrderDistalData {
  position: number[]
  lateralVector: number[]
  upVector: number[]
  frontalID: string
  lateralID: string
}

export enum OrderType {
  TRADITIONAL = 'traditional',
  SLICING = 'slicing',
}

export enum OrderSpecificity {
  NONE = 'none',
  DEMO = 'demo',
  TRAINING = 'training',
}

export enum OrderPrimaryProduct {
  SOCKET = 'socket',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  SOCKET_WITHOUT_ALIGN = 'socket_without_align',
}

export enum AmputationType {
  TRANSFEMORAL = 'transfemoral',
  TRANSTIBIAL = 'transtibial',
  TRANSRADIAL = 'transrasdial',
  UNKNOWN = 'unknown',
}

export interface Address {
  name: string
  street: string
  instruct: string
  cp: string
  city: string
  country: string
}
export interface Order {
  orderStep: OrderSteps
  wasToBeContinued: boolean
  orderVytruveID: string
  wrikeTaskID?: string
  primaryProduct: OrderPrimaryProduct
  specificity: OrderSpecificity
  wrikeLastKnownStatus?: string
  lastKnownStep?: string
  wrikeStepDone: OrderWrikeStep
  address: Address
  fileS3Key: string
  socketThickness?: string
  printerName?: string
  client?: string
  blNumber?: string
  orderDate: string
  estimatedDeliveryDate: string
  estimatedSentDate: string
  lastCheckedDate: string
  lastUpdatedDate: string
  deliveryService: OrderDeliveryService
  clientID?: string
  APIclientID: string
  fileID: string
  invoiceFileID?: string
  deliveryNoteFileID?: string
  invoiceFileName?: string
  agendaID?: string[]
  option: OrderOption
  strypeLink?: string
  trackingNum?: string
  obseleteVytruve: boolean
  vytruveVersionNumber: string
  ZPLBuffer?: Buffer
  starRating?: number
  reviewComment?: string
  createdAt: string
  distalData?: OrderDistalData
  orderType: OrderType
  amputation: AmputationType
  clientName: string
  commissionNumber?: string
}

export enum DeliveryNoteLanguage {
  FR = 'FR',
  EN = 'EN',
  DE = 'DE',
  IT = 'IT',
}
