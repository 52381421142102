import React from 'react'

import { Td, Tr } from '@chakra-ui/react'

import { Order } from 'src/types'
import formatAddress from '../../utils/formatAddress'
import { format } from '../../utils/date'

function OrderReviewLine({ order }: { order: Partial<Order> }) {
  return (
    <Tr>
      <Td>{order.orderVytruveID}</Td>
      <Td>{order.address && formatAddress(order.address)}</Td>
      <Td>{format(new Date(order.orderDate || new Date()), 'Pp')}</Td>
      <Td>{order.starRating}</Td>
      <Td>{order.reviewComment}</Td>
    </Tr>
  )
}

export default OrderReviewLine
