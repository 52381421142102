import axios from 'axios'

const authOverwrite =
  process.env.REACT_APP_LOGIN_BASIC_AUTH_PASSWORD && process.env.REACT_APP_LOGIN_BASIC_AUTH_USER
    ? {
        password: process.env.REACT_APP_LOGIN_BASIC_AUTH_PASSWORD,
        username: process.env.REACT_APP_LOGIN_BASIC_AUTH_USER,
      }
    : undefined

const Api = axios.create({
  auth: authOverwrite,
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: !authOverwrite,
})

export default Api
