import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { PaginatedResponse, PaginationQueryParams } from '../../types'
import Api from '../api'

interface FindAllHooksFactoryArgs {
  endpoint: string
  queryKey: string
}

export interface InfiniteQueryPage<T> {
  count: number
  nextPage: number
  results: T[]
  totalPages: number
}

function findAllHooksFactory<Entity, Params extends PaginationQueryParams>({
  endpoint,
  queryKey,
}: FindAllHooksFactoryArgs) {
  return {
    useGetEntity: (params: Params) => {
      const queryParams = new URLSearchParams('')

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(params)) {
        queryParams.set(key, value)
      }

      return useQuery([queryKey, params], async () => {
        const { data } = await Api.get<PaginatedResponse<Entity>>(`${endpoint}?${queryParams}`)
        return data
      })
    },
    useInfiniteGetEntity: (params: Omit<Params, 'offset'>) => {
      const fetchEntities = async ({ pageParam = 0 }): Promise<InfiniteQueryPage<Entity>> => {
        const { limit, ...rest } = params
        const newOffset = pageParam * limit
        const queryParams = new URLSearchParams('')

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(rest)) {
          queryParams.set(key, value)
        }

        queryParams.set('limit', limit.toString())
        queryParams.set('offset', newOffset.toString())

        const { data } = await Api.get<PaginatedResponse<Entity>>(`${endpoint}?${queryParams}`)
        return { count: data.count, nextPage: pageParam + 1, results: data.results, totalPages: data.count / limit }
      }

      return useInfiniteQuery([queryKey, params], fetchEntities, {
        getNextPageParam: (lastPage) => {
          if (lastPage.nextPage < lastPage.totalPages) return lastPage.nextPage
          return undefined
        },
      })
    },
  }
}

export default findAllHooksFactory
