import { Country } from 'src/types'
import english from 'i18n-iso-countries/langs/en.json'
import countries from 'i18n-iso-countries'

countries.registerLocale(english)

function countryName(countryCode: Country) {
  return countries.getName(countryCode, 'en') || countryCode
}

export default countryName
