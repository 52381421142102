export interface PaginatedResponse<T> {
  results: T[]
  count: number
}

export interface PaginationQueryParams {
  limit: number
  offset: number
  sort?: string
}

export enum TVorderTable {
  SEND_TO_DAY = 'send_to_day',
  TO_PRINT = 'to_print',
}

export enum Country {
  AFGHANISTAN = 'AF',
  ALANDISLANDS = 'AX',
  ALBANIA = 'AL',
  ALGERIA = 'DZ',
  AMERICANSAMOA = 'AS',
  ANDORRA = 'AD',
  ANGOLA = 'AO',
  ANGUILLA = 'AI',
  ANTARCTICA = 'AQ',
  ANTIGUAANDBARBUDA = 'AG',
  ARGENTINA = 'AR',
  ARMENIA = 'AM',
  ARUBA = 'AW',
  AUSTRALIA = 'AU',
  AUSTRIA = 'AT',
  AZERBAIJAN = 'AZ',
  BAHAMAS = 'BS',
  BAHRAIN = 'BH',
  BANGLADESH = 'BD',
  BARBADOS = 'BB',
  BELARUS = 'BY',
  BELGIUM = 'BE',
  BELIZE = 'BZ',
  BENIN = 'BJ',
  BERMUDA = 'BM',
  BHUTAN = 'BT',
  BOLIVIA = 'BO',
  BONAIRESINTEUSTATIUSSABA = 'BQ',
  BOSNIAANDHERZEGOVINA = 'BA',
  BOTSWANA = 'BW',
  BOUVETISLAND = 'BV',
  BRAZIL = 'BR',
  BRITISHINDIANOCEANTERRITORY = 'IO',
  BRUNEIDARUSSALAM = 'BN',
  BULGARIA = 'BG',
  BURKINAFASO = 'BF',
  BURUNDI = 'BI',
  CAMBODIA = 'KH',
  CAMEROON = 'CM',
  CANADA = 'CA',
  CAPEVERDE = 'CV',
  CAYMANISLANDS = 'KY',
  CENTRALAFRICANREPUBLIC = 'CF',
  CHAD = 'TD',
  CHILE = 'CL',
  CHINA = 'CN',
  CHRISTMASISLAND = 'CX',
  COCOSKEELINGISLANDS = 'CC',
  COLOMBIA = 'CO',
  COMOROS = 'KM',
  CONGO = 'CG',
  CONGODEMOCRATICREPUBLIC = 'CD',
  COOKISLANDS = 'CK',
  COSTARICA = 'CR',
  COTEDIVOIRE = 'CI',
  CROATIA = 'HR',
  CUBA = 'CU',
  CURAÇAO = 'CW',
  CYPRUS = 'CY',
  CZECHREPUBLIC = 'CZ',
  DENMARK = 'DK',
  DJIBOUTI = 'DJ',
  DOMINICA = 'DM',
  DOMINICANREPUBLIC = 'DO',
  ECUADOR = 'EC',
  EGYPT = 'EG',
  ELSALVADOR = 'SV',
  EQUATORIALGUINEA = 'GQ',
  ERITREA = 'ER',
  ESTONIA = 'EE',
  ETHIOPIA = 'ET',
  FALKLANDISLANDS = 'FK',
  FAROEISLANDS = 'FO',
  FIJI = 'FJ',
  FINLAND = 'FI',
  FRANCE = 'FR',
  FRENCHGUIANA = 'GF',
  FRENCHPOLYNESIA = 'PF',
  FRENCHSOUTHERNTERRITORIES = 'TF',
  GABON = 'GA',
  GAMBIA = 'GM',
  GEORGIA = 'GE',
  GERMANY = 'DE',
  GHANA = 'GH',
  GIBRALTAR = 'GI',
  GREECE = 'GR',
  GREENLAND = 'GL',
  GRENADA = 'GD',
  GUADELOUPE = 'GP',
  GUAM = 'GU',
  GUATEMALA = 'GT',
  GUERNSEY = 'GG',
  GUINEA = 'GN',
  GUINEABISSAU = 'GW',
  GUYANA = 'GY',
  HAITI = 'HT',
  HEARDISLANDMCDONALDISLANDS = 'HM',
  HOLYSEEVATICANCITYSTATE = 'VA',
  HONDURAS = 'HN',
  HONGKONG = 'HK',
  HUNGARY = 'HU',
  ICELAND = 'IS',
  INDIA = 'IN',
  INDONESIA = 'ID',
  IRAN = 'IR',
  IRAQ = 'IQ',
  IRELAND = 'IE',
  ISLEOFMAN = 'IM',
  ISRAEL = 'IL',
  ITALY = 'IT',
  JAMAICA = 'JM',
  JAPAN = 'JP',
  JERSEY = 'JE',
  JORDAN = 'JO',
  KAZAKHSTAN = 'KZ',
  KENYA = 'KE',
  KIRIBATI = 'KI',
  KOREA = 'KR',
  KOREADEMOCRATICPEOPLESREPUBLIC = 'KP',
  KUWAIT = 'KW',
  KYRGYZSTAN = 'KG',
  LAOPEOPLESDEMOCRATICREPUBLIC = 'LA',
  LATVIA = 'LV',
  LEBANON = 'LB',
  LESOTHO = 'LS',
  LIBERIA = 'LR',
  LIBYANARABJAMAHIRIYA = 'LY',
  LIECHTENSTEIN = 'LI',
  LITHUANIA = 'LT',
  LUXEMBOURG = 'LU',
  MACAO = 'MO',
  MACEDONIA = 'MK',
  MADAGASCAR = 'MG',
  MALAWI = 'MW',
  MALAYSIA = 'MY',
  MALDIVES = 'MV',
  MALI = 'ML',
  MALTA = 'MT',
  MARSHALLISLANDS = 'MH',
  MARTINIQUE = 'MQ',
  MAURITANIA = 'MR',
  MAURITIUS = 'MU',
  MAYOTTE = 'YT',
  MEXICO = 'MX',
  MICRONESIA = 'FM',
  MOLDOVA = 'MD',
  MONACO = 'MC',
  MONGOLIA = 'MN',
  MONTENEGRO = 'ME',
  MONTSERRAT = 'MS',
  MOROCCO = 'MA',
  MOZAMBIQUE = 'MZ',
  MYANMAR = 'MM',
  NAMIBIA = 'NA',
  NAURU = 'NR',
  NEPAL = 'NP',
  NETHERLANDS = 'NL',
  NEWCALEDONIA = 'NC',
  NEWZEALAND = 'NZ',
  NICARAGUA = 'NI',
  NIGER = 'NE',
  NIGERIA = 'NG',
  NIUE = 'NU',
  NORFOLKISLAND = 'NF',
  NORTHERNMARIANAISLANDS = 'MP',
  NORWAY = 'NO',
  OMAN = 'OM',
  PAKISTAN = 'PK',
  PALAU = 'PW',
  PALESTINIANTERRITORY = 'PS',
  PANAMA = 'PA',
  PAPUANEWGUINEA = 'PG',
  PARAGUAY = 'PY',
  PERU = 'PE',
  PHILIPPINES = 'PH',
  PITCAIRN = 'PN',
  POLAND = 'PL',
  PORTUGAL = 'PT',
  PUERTORICO = 'PR',
  QATAR = 'QA',
  REUNION = 'RE',
  ROMANIA = 'RO',
  RUSSIANFEDERATION = 'RU',
  RWANDA = 'RW',
  SAINTBARTHELEMY = 'BL',
  SAINTHELENA = 'SH',
  SAINTKITTSANDNEVIS = 'KN',
  SAINTLUCIA = 'LC',
  SAINTMARTIN = 'MF',
  SAINTPIERREANDMIQUELON = 'PM',
  SAINTVINCENTANDGRENADINES = 'VC',
  SAMOA = 'WS',
  SANMARINO = 'SM',
  SAOTOMEANDPRINCIPE = 'ST',
  SAUDIARABIA = 'SA',
  SENEGAL = 'SN',
  SERBIA = 'RS',
  SEYCHELLES = 'SC',
  SIERRALEONE = 'SL',
  SINGAPORE = 'SG',
  SINTMAARTEN = 'SX',
  SLOVAKIA = 'SK',
  SLOVENIA = 'SI',
  SOLOMONISLANDS = 'SB',
  SOMALIA = 'SO',
  SOUTHAFRICA = 'ZA',
  SOUTHGEORGIAANDSANDWICHISL = 'GS',
  SOUTHSUDAN = 'SS',
  SPAIN = 'ES',
  SRILANKA = 'LK',
  SUDAN = 'SD',
  SURINAME = 'SR',
  SVALBARDANDJANMAYEN = 'SJ',
  SWAZILAND = 'SZ',
  SWEDEN = 'SE',
  SWITZERLAND = 'CH',
  SYRIANARABREPUBLIC = 'SY',
  TAIWAN = 'TW',
  TAJIKISTAN = 'TJ',
  TANZANIA = 'TZ',
  THAILAND = 'TH',
  TIMORLESTE = 'TL',
  TOGO = 'TG',
  TOKELAU = 'TK',
  TONGA = 'TO',
  TRINIDADANDTOBAGO = 'TT',
  TUNISIA = 'TN',
  TURKEY = 'TR',
  TURKMENISTAN = 'TM',
  TURKSANDCAICOSISLANDS = 'TC',
  TUVALU = 'TV',
  UGANDA = 'UG',
  UKRAINE = 'UA',
  UNITEDARABEMIRATES = 'AE',
  UNITEDKINGDOM = 'GB',
  UNITEDSTATES = 'US',
  UNITEDSTATESOUTLYINGISLANDS = 'UM',
  URUGUAY = 'UY',
  UZBEKISTAN = 'UZ',
  VANUATU = 'VU',
  VENEZUELA = 'VE',
  VIETNAM = 'VN',
  VIRGINISLANDSBRITISH = 'VG',
  VIRGINISLANDSUS = 'VI',
  WALLISANDFUTUNA = 'WF',
  WESTERNSAHARA = 'EH',
  YEMEN = 'YE',
  ZAMBIA = 'ZM',
  ZIMBABWE = 'ZW',
}
