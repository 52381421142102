import React from 'react'

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import { Order, OrderDeliveryService } from '../../../types'
import useMutateOrder from '../../../hooks/orders/useMutateOrder'

interface OrderItemDeliveryServiceProps {
  order: Order
}

function OrderItemDeliveryService({ order }: OrderItemDeliveryServiceProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { mutate } = useMutateOrder(order.orderVytruveID)

  const handleOrderExpress = () => {
    mutate({ deliveryService: OrderDeliveryService.EXPRESS })
    onClose()
  }

  return (
    <Box>
      {order.deliveryService === OrderDeliveryService.EXPRESS ? (
        <Text color="red">{order.deliveryService}</Text>
      ) : (
        <Button colorScheme="gray" onClick={onOpen}>
          {order.deliveryService}
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{order.orderVytruveID}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to set the order in &quot;Express&quot;?</ModalBody>

          <ModalFooter>
            <Button marginRight="3" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="green" onClick={handleOrderExpress}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default OrderItemDeliveryService
