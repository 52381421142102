import React from 'react'

import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'

import useGetReviewOrders from '../../hooks/orders/useGetReviewOrders'
import OrderReviewViewLine from './OrderReviewLine'

function OrderReviewView() {
  const { data } = useGetReviewOrders()
  return (
    <TableContainer padding="4">
      <Table
        border="1.1px black solid"
        className="orderTable"
        colorScheme="gray"
        size="sm"
        variant="striped"
        width="full"
      >
        <Thead>
          <Tr>
            <Th>ORDERVYTRUVEID</Th>
            <Th>CLIENT</Th>
            <Th>ORDER DATE</Th>
            <Th>STAR RATING</Th>
            <Th>COMMENT</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((order) => (
            <OrderReviewViewLine order={order} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default OrderReviewView
