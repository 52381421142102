import { Address, Country } from 'src/types'
import countryName from './countryName'

function formatAddress(address: Address) {
  return `${address?.name || ''} ${address?.street || ''} ${address?.instruct || ''} ${address?.cp || ''} ${
    address?.city || ''
  } ${address?.country?.length === 2 ? countryName(address.country as Country) : address?.country || ''}`
}

export default formatAddress
