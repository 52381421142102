import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { ChakraProvider } from '@chakra-ui/react'

import './App.css'
import AppRoutes from './AppRoutes'

const queryClient = new QueryClient()

function App() {
  return (
    <ChakraProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        <div className="App">
          <header className="App-header" data-testid="header-app">
            <AppRoutes />
          </header>
        </div>
      </QueryClientProvider>
    </ChakraProvider>
  )
}

export default App
