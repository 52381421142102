import { Order, OrderSteps, PaginationQueryParams } from '../../types'
import findAllHooksFactory from '../../utils/factories/findAllHooksFactory'

export interface GetOrdersParams extends PaginationQueryParams {
  q?: string
  orderStep?: OrderSteps
}

export const { useGetEntity: useGetOrders, useInfiniteGetEntity: useInfiniteGetOrders } = findAllHooksFactory<
  Order,
  GetOrdersParams
>({
  endpoint: 'orders',
  queryKey: 'orders',
})
