import React from 'react'

import { Flex, RadioGroupProps, useRadioGroup, VStack, CheckboxProps, FlexProps } from '@chakra-ui/react'

import RadioButton from '../RadioButton'

interface RadioButtonGroupOption {
  badge?: string
  disabled?: boolean
  name?: string
  value: string | number
  isLoading?: boolean
  render?: (props: CheckboxProps & { isChecked: boolean }) => React.ReactElement
}

interface RadioButtonGroupProps extends Omit<RadioGroupProps, 'children'> {
  direction?: 'row' | 'column'
  options: RadioButtonGroupOption[]
  flexProps?: FlexProps
  // true by default. If false the input will be in the DOM with Opx w/h. Useful for focusing input on error
  hideInput?: boolean
}

const RadioButtonGroup = React.forwardRef(
  (
    { direction = 'column', options, value, flexProps, hideInput = true, ...rest }: RadioButtonGroupProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { getRootProps, getRadioProps } = useRadioGroup({ ...rest, value: value || '' })

    const group = getRootProps()

    return (
      <>
        <input hidden={hideInput} ref={ref} style={hideInput ? undefined : { height: '0px', width: '0px' }} />
        {direction === 'column' ? (
          <Flex {...group} {...flexProps} flexWrap="wrap" marginTop="-2" maxW="full">
            {options.map(({ badge, disabled, name, isLoading, render, value: optionValue }) => {
              const radio = getRadioProps({ disabled, value: optionValue })
              return (
                <RadioButton
                  {...radio}
                  _notLast={{ marginRight: '2' }}
                  badge={badge}
                  isDisabled={disabled}
                  isLoading={isLoading}
                  key={optionValue}
                  marginTop="var(--chakra-space-2)!important"
                  render={render}
                  title={name}
                />
              )
            })}
          </Flex>
        ) : (
          <VStack {...group} alignItems="flex-start" width="fit-content">
            {options.map(({ badge, disabled, name, isLoading, value: optionValue }) => {
              const radio = getRadioProps({ disabled, value: optionValue })
              return (
                <RadioButton
                  {...radio}
                  badge={badge}
                  isDisabled={disabled}
                  isLoading={isLoading}
                  key={optionValue}
                  title={name}
                  width="full"
                />
              )
            })}
          </VStack>
        )}
      </>
    )
  }
)

export default RadioButtonGroup
