import React from 'react'

import { Table, Tr, Th, Thead, Tbody, Text, Divider } from '@chakra-ui/react'

import { TVorderTable } from '../../types'
import { useGetOrdersPrintToday } from '../../hooks/orders/useGetOrdersPrintToday'
import { useGetOrdersGoToday } from '../../hooks/orders/useGetOrdersGoToday'

import DisplayOrderItem from './DisplayOrderItem'
import OrderTableSlicing from './OrderTableSlicing'

function TVView() {
  const { data: orderGoToday } = useGetOrdersGoToday({ limit: 10000, offset: 0 })
  const { data: orderPrintToday } = useGetOrdersPrintToday({ limit: 10000, offset: 0 })

  const listItemsToSend =
    orderGoToday &&
    orderGoToday.results?.map?.((itm) => (
      <DisplayOrderItem key={itm.orderVytruveID} order={itm} type={TVorderTable.SEND_TO_DAY} />
    ))
  const listItemsToPrint =
    orderPrintToday &&
    orderPrintToday.results?.map?.((itm) => (
      <DisplayOrderItem key={itm.orderVytruveID} order={itm} type={TVorderTable.TO_PRINT} />
    ))

  return (
    <div className="TVView">
      <meta content="300" httpEquiv="refresh" />
      <Divider />
      <OrderTableSlicing />
      <Divider />
      <Text fontSize="4xl">Orders to Send today ({orderGoToday?.count})</Text>
      <Table colorScheme="cyan" size="sm" variant="striped" width="full">
        <Thead>
          <Tr>
            <Th>
              <h3>
                <b>VytruveID</b>
              </h3>
            </Th>
            <Th>
              <h3>
                <b>Est. Sent</b>
              </h3>
            </Th>
            <Th>
              <h3>
                <b>Client</b>
              </h3>
            </Th>
            <Th>Type</Th>
            <Th>orderStep</Th>
            <Th>orderDate</Th>
            <Th>Est. Delivery</Th>
            <Th>deliveryService</Th>
            <Th>platine</Th>
            <Th>distal</Th>
            <Th>valve</Th>
            <Th>Support manchons</Th>
            <Th>trackingNum</Th>
          </Tr>
        </Thead>
        <Tbody>{listItemsToSend}</Tbody>
      </Table>
      <Divider />
      <Text fontSize="4xl">Orders to print ({orderPrintToday?.count})</Text>
      <Table colorScheme="gray" size="sm" variant="striped" width="full">
        <Thead>
          <Tr>
            <Th>
              <h3>
                <b>VytruveID</b>
              </h3>
            </Th>
            <Th>
              <h3>
                <b>Est. Sent</b>
              </h3>
            </Th>
            <Th>Socket thickness</Th>
            <Th>
              <h3>
                <b>Client</b>
              </h3>
            </Th>
            <Th>Type</Th>
            <Th>orderStep</Th>
            <Th>orderDate</Th>
            <Th>Est. Delivery</Th>
            <Th>deliveryService</Th>
            <Th>platine</Th>
            <Th>distal</Th>
            <Th>valve</Th>
            <Th>Support manchons</Th>
            <Th>trackingNum</Th>
          </Tr>
        </Thead>
        <Tbody>{listItemsToPrint}</Tbody>
      </Table>
    </div>
  )
}

export default TVView
