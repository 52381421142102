import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Order } from 'src/types'
import Toast from '../../componants/toast'
import Api from '../../utils/api'

function useMutateOrder(id: string) {
  const toast = Toast()
  const queryClient = useQueryClient()
  return useMutation(
    ['mutateOrder'],
    async (body: Partial<Order>) => {
      const { data } = await Api.patch<string>(`/order`, { ...body, orderVytruveID: id })
      return data
    },
    {
      onError: (error: AxiosError) => {
        toast({
          description: error.message,
          status: 'error',
          title: 'Something went wrong title',
        })
      },

      onSuccess: () => {
        queryClient.invalidateQueries(['orders'])
        toast({
          description: 'Order is successfully updated',
          status: 'success',
          title: 'Order updated',
        })
      },
    }
  )
}

export default useMutateOrder
