import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { IoSearch } from 'react-icons/io5'

import {
  Text,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Progress,
  Box,
  HStack,
  Input,
  InputLeftElement,
  InputGroup,
  Icon,
} from '@chakra-ui/react'

import { Order, PaginationQueryParams } from 'src/types'
import useSearchParamsFilters from '../../hooks/utils/useSearchParamsFilters'
import useDebounce from '../../hooks/utils/useDebounce'
import { useInfiniteGetOrders, GetOrdersParams } from '../../hooks/orders/useGetOrders'

import OrderItem from './OrderItem/OrderItem'
import OrderForm from './OrderForm'

function OrderView() {
  const { parsedSearchParams: searchParams, setSearchParams } =
    useSearchParamsFilters<Omit<GetOrdersParams, keyof PaginationQueryParams>>()

  const [innerSearch, setInnerSearch] = useState(searchParams.q || '')
  const debouncedSearch = useDebounce(innerSearch, 300)
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteGetOrders({ limit: 10, ...searchParams })

  const [tableData, setTableData] = useState<Order[]>()
  const { ref: loadMoreRef, inView } = useInView()
  const handleSearchChange = (query: string) => {
    setSearchParams({ ...searchParams, q: query })
  }

  useEffect(() => {
    if (inView && fetchNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView, JSON.stringify(data?.pages)])

  useEffect(() => {
    if (data) {
      const flattenedOrders = data.pages.reduce(
        (orders, ordersPage) => [...orders, ...ordersPage.results],
        [] as Order[]
      )
      setTableData(flattenedOrders)
    }
  }, [JSON.stringify(data)])

  useEffect(() => {
    handleSearchChange(debouncedSearch)
  }, [debouncedSearch])

  return (
    <Box className="OrderView" width="full">
      <Text as="h1" fontSize="2xl">
        Vytruve orders
      </Text>
      <Text as="h2" fontSize="xl">
        Create order
      </Text>
      <OrderForm />
      <HStack justifyContent="space-between" padding="4" width="full">
        <Text as="h2" fontSize="xl">
          Orders In progress
        </Text>
        <Box maxWidth="full" width="xs">
          <InputGroup bgColor="white" width="full">
            <InputLeftElement pointerEvents="none">
              <Icon as={IoSearch} />
            </InputLeftElement>
            <Input
              placeholder="Search..."
              rounded="md"
              type="search"
              value={innerSearch}
              onChange={(event) => {
                setInnerSearch(event.target.value)
              }}
            />
          </InputGroup>
        </Box>
      </HStack>
      <Table className="orderTable" colorScheme="gray" size="sm" variant="striped" width="full">
        <Thead>
          <Tr>
            <Th>orderVytruveID</Th>
            <Th>Vers.</Th>
            <Th>orderStep</Th>
            <Th>Amputation</Th>
            <Th>Type</Th>
            <Th>Primary Product</Th>
            <Th>Socket thickness</Th>
            <Th>Client</Th>
            <Th>address</Th>
            <Th>orderDate</Th>
            <Th>Est. Sent</Th>
            <Th>Est. Delivery</Th>
            <Th>deliveryService</Th>
            <Th>platine</Th>
            <Th>P.distale</Th>
            <Th>valve</Th>
            <Th>Support manchons</Th>
            <Th>trackingNum</Th>
            <Th>Design File</Th>
            <Th>Delivery File</Th>
            <Th>ZPL</Th>
            <Th>Cancel</Th>
          </Tr>
        </Thead>
        <Tbody width="full">
          {tableData && tableData.map?.((itm) => <OrderItem key={itm.orderVytruveID} order={itm} />)}
        </Tbody>
      </Table>
      {(hasNextPage || isLoading) && (
        <Box padding="6" ref={isLoading ? null : loadMoreRef}>
          <Progress isIndeterminate size="xs" width="full" />
        </Box>
      )}
    </Box>
  )
}

export default OrderView
