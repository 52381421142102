import React from 'react'
import { FaPrint } from 'react-icons/fa'

import {
  Button,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'

import useReprintZpl from '../../../hooks/orders/useReprintZpl'
import { Order } from '../../../types'

interface ZplFileReprintButtonProps {
  order: Order
}

function ZplFileReprintButton({ order }: ZplFileReprintButtonProps) {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { mutate } = useReprintZpl(order.orderVytruveID)

  const handleConfirm = () => {
    onClose()
    mutate()
  }

  return (
    <>
      <IconButton
        aria-label="reprint zpl file"
        colorScheme="green"
        data-testid="button-reprint-zpl"
        icon={<Icon as={FaPrint} />}
        size="sm"
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{order.orderVytruveID}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want reprint the zpl file? The zpl file will be printed in 11 minutes maximum
          </ModalBody>

          <ModalFooter>
            <Button marginRight="3" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="green" onClick={handleConfirm}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ZplFileReprintButton
