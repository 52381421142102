import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import Toast from '../../componants/toast'

import Api from '../../utils/api'

function useReprintZpl(id: string) {
  const toast = Toast()
  return useMutation(
    ['orders-reprint-zpl'],
    async () => {
      const { data } = await Api.get<string>(`/orders/reprint-zpl/${id}`)
      return data
    },
    {
      onError: (error: AxiosError) => {
        toast({
          description: error.message,
          status: 'error',
          title: 'Something went wrong title',
        })
      },

      onSuccess: () => {
        toast({
          description: 'Zpl will be printed in 11 minutes maximum.',
          status: 'success',
          title: 'Zpl file in printing queue',
        })
      },
    }
  )
}

export default useReprintZpl
