import React from 'react'

import { Badge, Box, Radio, RadioProps, Spinner, Text, useRadio } from '@chakra-ui/react'

export interface RadioButtonProps extends Omit<RadioProps, 'children'> {
  badge?: string
  title?: string
  isLoading?: boolean
  render?: (props: any & { isChecked: boolean }) => React.ReactElement
}

function RadioButton({
  title,
  badge,
  isLoading,
  marginRight,
  marginTop,
  width,
  _notLast,
  render,
  ...rest
}: RadioButtonProps) {
  const {
    getInputProps,
    getCheckboxProps,
    state: { isChecked, isDisabled },
  } = useRadio(rest)

  const input = getInputProps()
  const checkbox = getCheckboxProps()
  const { id, style, ...radioProps } = input

  return (
    <Box _notLast={_notLast} as="label" marginRight={marginRight} marginTop={marginTop} width={width}>
      <input {...input} />
      {render ? (
        render({ ...checkbox, isChecked })
      ) : (
        <Box
          {...checkbox}
          _checked={
            !isChecked
              ? { borderColor: 'gray.200' }
              : {
                  bgColor: 'blue.50',
                  borderColor: 'blue.500',
                }
          }
          _disabled={{
            cursor: 'not-allowed',
          }}
          _invalid={{
            bgColor: 'red.50',
            borderColor: 'red.500',
          }}
          alignItems="center"
          bgColor="white"
          borderRadius="md"
          borderWidth="2px"
          cursor="pointer"
          display="flex"
          minHeight="10"
          paddingX="4"
        >
          <Radio {...radioProps} _hover={{ cursor: 'pointer' }} isChecked={isChecked} marginRight="2" />
          <Text
            color={isDisabled ? 'gray.300' : 'black'}
            fontSize="sm"
            fontWeight="semibold"
            paddingY="2"
            sx={{ wordWrap: 'break-word' }}
            textAlign="start"
            whiteSpace="normal"
          >
            {title}
          </Text>
          {badge && (
            <Badge colorScheme="red" fontSize="xs" marginLeft="2" rounded="md">
              {badge}
            </Badge>
          )}
          {isLoading && <Spinner color="blue.500" marginLeft={4} />}
        </Box>
      )}
    </Box>
  )
}

RadioButton.defaultProps = {
  badge: undefined,
}

export default RadioButton
