import React from 'react'
import { BiCommentDetail } from 'react-icons/bi'

import { Box, Icon, Text, Tooltip, Wrap } from '@chakra-ui/react'

import { Order, OrderSpecificity } from '../../../types'

interface OrderItemReferenceProps {
  order: Order
}

function OrderItemReference({ order }: OrderItemReferenceProps) {
  return (
    <Wrap spacing="2">
      {order.deliveryService === 'express' ? (
        <Text color="red" fontWeight="bold" wordBreak="break-all">
          {order.orderVytruveID}
        </Text>
      ) : (
        <Text>{order.orderVytruveID}</Text>
      )}
      <Tooltip hasArrow label={order.specificity.charAt(0).toUpperCase() + order.specificity.slice(1)}>
        <Box cursor="pointer">
          {order.specificity === OrderSpecificity.DEMO && (
            <Text color="blue.500" fontWeight="bold">
              D
            </Text>
          )}
          {order.specificity === OrderSpecificity.TRAINING && (
            <Text color="orange.500" fontWeight="bold">
              T
            </Text>
          )}
        </Box>
      </Tooltip>
      {order.reviewComment && (
        <Tooltip hasArrow label={order.reviewComment}>
          <span data-testid="comment-icon">
            <Icon as={BiCommentDetail} />
          </span>
        </Tooltip>
      )}
    </Wrap>
  )
}

export default OrderItemReference
