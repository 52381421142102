import { Button, Center, Icon } from '@chakra-ui/react'
import React from 'react'
import { FaGoogle } from 'react-icons/fa'

function Login() {
  const handleClick = () => {
    window.location.replace(`${process.env.REACT_APP_API_BASE_URL}/login/google`)
  }

  return (
    <Center height="100vh" width="full">
      <Button colorScheme="blue" leftIcon={<Icon as={FaGoogle} />} size="lg" variant="outline" onClick={handleClick}>
        Sign in with google
      </Button>
    </Center>
  )
}

export default Login
