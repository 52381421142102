import { Order, PaginationQueryParams } from '../../types'
import findAllHooksFactory from '../../utils/factories/findAllHooksFactory'

export interface GetOrdersPrintTodayParams extends PaginationQueryParams {
  q?: string
}

export const { useGetEntity: useGetOrdersPrintToday, useInfiniteGetEntity: useInfiniteGetOrdersPrintToday } =
  findAllHooksFactory<Order, GetOrdersPrintTodayParams>({
    endpoint: 'orders/printtoday',
    queryKey: 'orders-print',
  })
