import { PaginationQueryParams, Client } from '../../types'
import findAllHooksFactory from '../../utils/factories/findAllHooksFactory'

export interface GetClientsParams extends PaginationQueryParams {
  q?: string
}

export const { useGetEntity: useGetClients, useInfiniteGetEntity: useInfiniteGetClients } = findAllHooksFactory<
  Client,
  GetClientsParams
>({
  endpoint: 'clients/API',
  queryKey: 'clients',
})
