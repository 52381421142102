import { OrderSteps } from '../types'

export const orderSteps = [
  {
    label: 'Need slicing',
    value: OrderSteps.NEED_SLICING,
  },
  {
    label: 'Slicing done',
    value: OrderSteps.SLICING_DONE,
  },
]
