/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'

import { Button, Flex, FormControl, Grid, HStack, Input, VStack } from '@chakra-ui/react'

import RadioButtonGroup from '../../componants/RadioButtonGroup'
import { OrderType } from '../../types'
import { useGetClients } from '../../hooks/client/useGetClients'

function OrderForm() {
  const { data } = useGetClients({ limit: 5000000, offset: 0 })

  const [typeOfOrder, setTypeOfOrder] = useState<OrderType>(OrderType.TRADITIONAL)

  return (
    <VStack alignItems="flex-start" marginTop="2" paddingX="4" spacing="2" width="full">
      <FormControl alignItems="center" display="flex">
        <RadioButtonGroup
          defaultValue={OrderType.TRADITIONAL}
          id="orderType"
          options={[
            { name: 'Traditional', value: OrderType.TRADITIONAL },
            { name: 'Slicing', value: OrderType.SLICING },
          ]}
          value={typeOfOrder}
          onChange={(value: OrderType) => setTypeOfOrder(value)}
        />
      </FormControl>
      <form
        action={`${process.env.REACT_APP_API_BASE_URL}/order`}
        encType="multipart/form-data"
        id="FromPostOrder"
        method="post"
        style={{ width: '100%' }}
      >
        <Grid gap={1} templateColumns="repeat(2, 1fr)" width="full">
          <HStack>
            <label htmlFor="orderDate">Order Date </label>
            <Input required autoComplete="off" id="orderDate" name="orderDate" size="xs" type="datetime-local" />
            <br />
          </HStack>
          <HStack>
            <label htmlFor="orderVytruveID">VytruveID </label>
            <Input required autoComplete="off" id="orderVytruveID" name="orderVytruveID" size="xs" type="text" />
            <br />
          </HStack>
          <HStack>
            <label htmlFor="clientidVytruve">clientidVytruve </label>
            <select required autoComplete="off" id="clientidVytruve" name="clientidVytruve">
              <option value="">--Select--</option>
              {data &&
                data.results.map((client) => (
                  <option key={client.uniqueId} value={client.uniqueId}>
                    {client.legalName} {client.contactEmail}
                  </option>
                ))}
            </select>
            <br />
          </HStack>
          {typeOfOrder === OrderType.TRADITIONAL && (
            <>
              <HStack>
                <label htmlFor="deliverycity">City </label>
                <Input required autoComplete="off" id="deliverycity" name="deliverycity" size="xs" type="text" />
                <br />
              </HStack>
              <HStack>
                <label htmlFor="deliverycp">CP </label>
                <Input required autoComplete="off" id="deliverycp" name="deliverycp" size="xs" type="text" />
                <br />
              </HStack>
              <HStack>
                <label htmlFor="deliveryname">Name </label>
                <Input required autoComplete="off" id="deliveryname" name="deliveryname" size="xs" type="text" />
                <br />
              </HStack>
              <HStack>
                <label htmlFor="deliverystreet">Street </label>
                <Input required autoComplete="off" id="deliverystreet" name="deliverystreet" size="xs" type="text" />
                <br />
              </HStack>
              <HStack>
                <label htmlFor="deliveryinstruct">Delivery instruction </label>
                <Input
                  required
                  autoComplete="off"
                  id="deliveryinstruct"
                  name="deliveryinstruct"
                  size="xs"
                  type="text"
                />
                <br />
              </HStack>
              <HStack>
                <label htmlFor="deliverycountry">Country</label>
                <Input required autoComplete="off" id="deliverycountry" name="deliverycountry" size="xs" type="text" />
                <br />
              </HStack>
              <HStack>
                <label htmlFor="deliveryspeed">Delivery speed </label>
                <select required autoComplete="off" id="deliveryspeed" name="deliveryspeed">
                  <option value="">--Select--</option>
                  <option value="standard">standard</option>
                  <option value="express">express</option>
                </select>
                <br />
              </HStack>
              <HStack>
                <label htmlFor="platineType">Add platine</label>
                <select required autoComplete="off" id="platineType" name="platineType">
                  <option value="">--Select--</option>
                  <option value="none">none</option>
                  <option value="CA133STMX">CA133STMX</option>
                  <option value="CA134STMX">CA134STMX</option>
                </select>
                <br />
              </HStack>
              <HStack>
                <label htmlFor="distalType">Add distal</label>
                <select required autoComplete="off" id="distalType" name="distalType">
                  <option value="">--Select--</option>
                  <option value="none">none</option>
                  <option value="LisseL651000">Lisse L-651000</option>
                  <option value="Crante6A2002">Crante 6A200=2</option>
                  <option value="BOAXPK3000">BOA XPK3000</option>
                  <option value="BOAXLR30107">BOA XLR30107</option>
                </select>
                <br />
              </HStack>
            </>
          )}
          <HStack>
            <label htmlFor="socketThickness">Socket Thickness</label>
            <Input
              required
              autoComplete="off"
              defaultValue="0.70"
              id="socketThickness"
              name="socketThickness"
              size="xs"
              type="text"
            />
            <br />
          </HStack>
          <HStack>
            <label htmlFor="obseleteVytruve">Vytruve version obselete</label>
            <select required autoComplete="off" id="obseleteVytruve" name="obseleteVytruve">
              <option value="">--Select--</option>
              <option value="no">no</option>
              <option value="yes">yes</option>
            </select>
            <br />
          </HStack>
          <Input required autoComplete="off" name="STL" size="xs" type="file" />
          {typeOfOrder === OrderType.SLICING && (
            <Input required display="none" id="slicing" name="slicing" size="xs" type="text" value="slicing" />
          )}
        </Grid>
        <Flex justifyContent="flex-end" width="full">
          <Button colorScheme="blue" mt="2" size="sm" type="submit" value="Submit" variant="outline">
            Submit
          </Button>
        </Flex>
      </form>
    </VStack>
  )
}

export default OrderForm
